import React from 'react';
import PropTypes from 'prop-types';
import './text.scss';

const Text = ({ children, size, className }) => {
    const classes = `text text_${size} ${className}`;

    return <p className={classes}>{children}</p>;
};

Text.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
};

export default Text;
