import React, { useState, useEffect } from 'react';
import map from 'lodash/map';
import { Link } from 'gatsby';
import './navigation.scss';
import MenuToggle from './menu-toggle';
import AnimateIn from './animate-in';

const navLinks = [
    {
        name: 'Home',
        url: '/',
    },
    {
        name: 'The Wedding',
        url: '/wedding',
    },
    {
        name: 'Travel',
        url: '/travel',
    },
    {
        name: 'Registry',
        url: '/registry',
    },
];

const NavLinkList = () => {
    return (
        <ul>
            {map(navLinks, (link) => (
                <li key={link.name}>
                    <Link
                        className="secondary-hover"
                        to={link.url}
                        activeClassName="active"
                    >
                        {link.name}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

const Navigation = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'scroll';
        }
    }, [menuOpen]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <nav className={`nav ${menuOpen ? 'open' : ''}`}>
            <div className="main-nav">
                <div className="nav--left">
                    <Link className="secondary-hover" to="/">
                        M &amp; A
                    </Link>
                </div>
                <div className="nav--right">
                    <NavLinkList />
                    <MenuToggle
                        toggleHandler={toggleMenu}
                        menuOpen={menuOpen}
                    />
                </div>
            </div>

            {menuOpen && (
                <div className="nav--menu">
                    <AnimateIn onScroll direction="none">
                        <NavLinkList />
                    </AnimateIn>
                </div>
            )}
        </nav>
    );
};

export default Navigation;
