import React from 'react';
import './menu-toggle.scss';

const CloseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="3.41431"
                y="20"
                width="24"
                height="2"
                transform="rotate(-45 3.41431 20)"
                fill="#2E2E2E"
            />
            <rect
                x="4.41431"
                y="3"
                width="24"
                height="2"
                transform="rotate(45 4.41431 3)"
                fill="#2E2E2E"
            />
        </svg>
    );
};

const BurgerIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect y="4" width="22" height="2" fill="#2E2E2E" />
            <rect y="11" width="20" height="2" fill="#2E2E2E" />
            <rect y="18" width="24" height="2" fill="#2E2E2E" />
        </svg>
    );
};

const MenuToggle = ({ toggleHandler, menuOpen }) => {
    const label = menuOpen ? 'Close navigation menu' : 'Open navigation menu';
    const className = `svg-button ${menuOpen ? 'close-button' : 'open-button'}`;
    return (
        <div className="menu-toggle">
            <button
                aria-label={label}
                className={className}
                onClick={toggleHandler}
            >
                {menuOpen ? <CloseIcon /> : <BurgerIcon />}
            </button>
        </div>
    );
};

export default MenuToggle;
