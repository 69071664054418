import React, { useRef, useEffect } from 'react';
import includes from 'lodash/includes';
import './cursor.scss';

const Cursor = ({ appRef }) => {
    const cursorRef = useRef();

    useEffect(() => {
        const cursor = cursorRef.current;
        const app = appRef.current;

        const onMouseEnter = (event) => {
            cursor.style.opacity = 1;

            cursor.style.top = event.clientY + 'px';
            cursor.style.left = event.clientX + 'px';
        };

        const onMouseLeave = () => {
            cursor.style.opacity = 0;
        };

        const onMouseMove = (event) => {
            cursor.style.top = event.clientY + 'px';
            cursor.style.left = event.clientX + 'px';

            const e = window.e || event;

            if (e.target.tagName === 'A') {
                if (!includes(e.target.classList, 'secondary-hover')) {
                    if (!includes(cursor.classList, 'active')) {
                        cursor.classList.add('active');
                    }
                } else {
                    if (!includes(cursor.classList, 'secondary-active')) {
                        cursor.classList.add('secondary-active');
                    }
                }
            } else {
                if (includes(cursor.classList, 'active')) {
                    cursor.classList.remove('active');
                }
                if (includes(cursor.classList, 'secondary-active')) {
                    cursor.classList.remove('secondary-active');
                }
            }
        };

        app.addEventListener('mousemove', onMouseMove, false);
        app.addEventListener('mouseenter', onMouseEnter, false);
        app.addEventListener('mouseleave', onMouseLeave, false);

        return () => {
            app.removeEventListener('mousemove', onMouseMove, false);
            app.removeEventListener('mouseenter', onMouseEnter, false);
            app.removeEventListener('mouseleave', onMouseLeave, false);
        };
    });

    return <span className="cursor" ref={cursorRef} />;
};

export default Cursor;
