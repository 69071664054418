import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { InView } from 'react-intersection-observer';

const AnimateIn = (props) => {
    const {
        children,
        delay = 0,
        direction = 'up',
        onScroll = false,
        trigger = false,
    } = props;

    const getTransformation = (direction, state) => {
        let axis = 'Y';
        let distance = 24;

        if (direction === 'left' || direction === 'right') {
            axis = 'X';
        }

        if (state === 'entered' || direction === 'none') {
            distance = 0;
        } else if (direction === 'down' || direction === 'right') {
            distance = -distance;
        }

        return `translate${axis}(${distance}px)`;
    };

    const getStyles = (state) => {
        return {
            transition: 'opacity 1s ease, transform 1.2s ease',
            transitionDelay: `${delay}ms`,
            transform: `${getTransformation(direction, state)}`,
            opacity: `${state === 'entered' ? '1' : '0'}`,
        };
    };

    return (
        <InView triggerOnce threshold={0}>
            {({ inView, ref }) => (
                <CSSTransition
                    in={onScroll ? inView : trigger}
                    timeout={150}
                    exit={false}
                >
                    {(state) => (
                        <div ref={ref} style={getStyles(state)}>
                            {children}
                        </div>
                    )}
                </CSSTransition>
            )}
        </InView>
    );
};

export default AnimateIn;
