import React from 'react';
import Text from './text';
import './sidebar.scss';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <Text size="small" className="sidebar--text">
                #TheDunaDuo
            </Text>
        </div>
    );
};

export default Sidebar;
