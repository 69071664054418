import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from './sidebar';
import Navigation from './navigation';
import Cursor from './cursor';
import favicon from '../assets/favicon.ico';
import favicon16 from '../assets/favicon-16x16.png';
import favicon32 from '../assets/favicon-32x32.png';

import './layout.scss';

const Layout = ({ children, className, wide }) => {
    const appRef = useRef();

    return (
        <main
            className={`layout ${wide ? 'layout_wide' : ''} ${className}`}
            ref={appRef}
        >
            <Helmet>
                <link rel="icon" href={favicon} />
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon32}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={favicon16}
                    sizes="16x16"
                />
            </Helmet>
            <Cursor appRef={appRef} />
            <div className="grain-layer" />
            <Sidebar />
            <Navigation />
            <div className="layout--content">{children}</div>
        </main>
    );
};

export default Layout;
